body {
  margin: 0;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  /*overflow-x: hidden;*/
  /*-webkit-overflow-scrolling: touch;*/
}

html {
  background-color: #F5F5F5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
